<template>
    <div class="all">
        <!-- 地图 -->
        <div class="all_l">
            <div class="select-box">
                <el-date-picker
                        v-model="date"
                        type="daterange"
                        range-separator="-"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOptions"
                        value-format="yyyy-MM-dd"
                        placement="bottom-start"
                        size="small"
                        @change="handleDate"
                        :clearable="false"
                ></el-date-picker>
            </div>
            <div class="l_box">
                <img src="../assets/img/1-title-map@2x.png" alt=""/>
                <div class="tit_box">人次（人）</div>
                <div class="tit">{{ today_people }}</div>
                <div class="tit_box">营业额（元）</div>
                <div class="tit">{{ today_price }}</div>
                <div class="tit_box">订单数量（笔）</div>
                <div class="tit">{{ today_order }}</div>
            </div>
            <div class="l_img">
                <!--        <div class="mask-box">-->
                <!--          <p class="p1">虞山度假区总游客： </p>-->
                <!--          <p class="p2">{{ysAll}}人</p>-->
                <!--        </div>-->
                <!--        <div class="mask-box2">-->
                <!--          <p class="p1">沙家浜度假区总游客： </p>-->
                <!--          <p class="p2">{{sjbAll}}人</p>-->
                <!--        </div>-->

                <img src="../assets/img/1-map@2x.png" alt=""/>
                <div class="scenic" v-for="(item, index2) in mapList" :key="item.id" :style="point(index2)">
                    <div class="scenic_top">
                        {{ item.name }}
                        <span class="num">{{ item.num }}</span>
                        人
                    </div>
                    <img src="../assets/img/map-icon2@2x.png" class="scenic_bottom"/>
                </div>
            </div>
        </div>
        <!-- 统计 -->
        <div class="all_r">
            <img src="../assets/img/1-title-shishi@2x.png" alt="" class="r_tit"/>
            <!--            <div class="echart_box">-->
            <!--                <div class="echart_l">-->
            <!--                    <Echart :options="options" id="bottom2Chart" height="224px" width="224px"></Echart>-->
            <!--                </div>-->
            <!--                <div class="echart_r">-->
            <!--                    <div class="echart_r_box" v-for="(item3, index3) in saleList" :key="index3">-->
            <!--                        <div class="echart_r_1">-->
            <!--                            <div :style="showBg(item3.type)"></div>-->
            <!--                            <div :style="showBg(item3.type)" class="echart_r_1_m"></div>-->
            <!--                            <div :style="showBg(item3.type)"></div>-->
            <!--                            <p>{{ item3.name }}</p>-->
            <!--                        </div>-->
            <!--                        <div class="echart_r_2">-->
            <!--                            <span class="num">{{ item3.format_price }}</span>-->
            <!--                            元-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->

            <div class="r_bottom_tit all-date-box">
                <img src="../assets/img/1-title-ticket@2x (1).png" alt="" class="r_tit"/>
<!--                <el-dropdown trigger="click" placement="bottom" @command="handleCommand">-->
<!--                    <div class="el-dropdown-link">-->
<!--                        <span>{{ chose }}</span>-->
<!--                        <img src="../assets/img/1-title-parking-icon3@2x.png" alt=""/>-->
<!--                    </div>-->
<!--                    <el-dropdown-menu slot="dropdown">-->
<!--                        <el-dropdown-item command="">全部景区</el-dropdown-item>-->
<!--                        <el-dropdown-item v-for="item in mapList" :key="item.id" :command="item.id">-->
<!--                            {{ item.name }}-->
<!--                        </el-dropdown-item>-->
<!--                    </el-dropdown-menu>-->
<!--                </el-dropdown>-->
                <el-cascader
                        v-model="Jqvalue2"
                        placeholder="景区停车场选择"
                        size="mini"
                        :options="Jqoptions2"
                        :props="{ children: 'list',label:'name',value:'id'}"
                        @change="changeJq2()"></el-cascader>

            </div>
            <div class="r_bottom">
                <div>
                    <img src="../assets/img/1-title-parking-icon4@2x.png" alt=""/>
                    <div class="r_bottom_num">{{ park.last_num }}</div>
                    <div>剩余车位(个)</div>
                </div>
                <div>
                    <img src="../assets/img/1-title-parking-icon4@2x.png" alt=""/>
                    <div class="r_bottom_num">{{ park.used_num }}</div>
                    <div>已使用车位(个)</div>
                </div>
                <img src="../assets/img/1-title-parking-icon5@2x.png" alt="" class="r_bottom_2"/>
                <div>
                    <img src="../assets/img/1-title-parking-icon4@2x.png" alt=""/>
                    <div class="r_bottom_num">{{ park.total_num }}</div>
                    <div>车位总数(个)</div>
                </div>
            </div>

            <img src="../assets/img/1-title-ticket-money@2x.png" alt="" class="r_tit2"/>
            <div class="r_next">
                <div class="info-box">

                    <div class="info2-box" style="margin: 0 auto">
                        <dv-loading v-if="loading1" style="height: 237px">Loading...</dv-loading>
                        <div v-else class="right_echart_box">
                            <div class="echart_l">
                                <Echart :options="options1" id="chart1" height="248px" width="248px"></Echart>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="info-box">

                    <div class="info3-box">
                        <div class="echart_r">
                            <div class="echart_r_box" v-for="(item1, index1) in xData1"
                                 :key="index1">
                                <div class="echart_r_box1" :style="show1(index1+4, '4px')">{{ item1.name }}</div>
                                <div class="echart_r_box2" :style="show1(index1+4, '1px')">{{ item1.value }} <span>({{item1.percent}}%)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <img v-if="xData1.length < 1" src="../assets/img/1-title-ticket-empty@2x.png" alt="" class="empty"/>
            </div>
        </div>
    </div>
</template>

<script>
    import Echart from "@/common/echart";
    import {getOverview, getTop5, getPark, getMap, getSale, tjOverviewParkIncome, tjParkList} from "../api";
    import {formatTime} from "../utils";

    export default {
        components: {
            Echart,
        },
        props: {},
        data() {
            return {
                options: {},
                today_people: "", // 今日人次
                today_price: "", // 今日营业额(元)
                today_order: "", // 今日订单数量
                top5List: [], // 销量top5
                allNum: 0,
                xData1: [],
                loading1: false,
                colorList: ["#5B8FF9", "#207BF0", "#37D2FF", "#4EEB9B", "#00F4E0", "#604EEB",
                    '#DF4EEB', '#EB4EBD', '#FF99C3', '#FF6F6F', '#EBA94E', '#B6D85A', '#AA4EEB'],
                options1: {},
                mapList: [], // 地图景区
                saleList: [], // 销售额
                allSale: "", // 总销售额
                park: {}, // 停车数据
                chose: "全部景区",
                // 虞山总游客
                ysAll: '',
                // 沙家浜总游客
                sjbAll: '',
                date: '',
                // 感觉认识很久的朋友，相互联系才能维系
                pickerOptions: { // 限制日期范围为一个月
                    onPick: ({maxDate, minDate}) => {
                        this.isRestart = false;
                        this.pickerMinDate = minDate.getTime();
                        if (maxDate) {
                            this.pickerMinDate = "";
                        }
                    },
                    disabledDate: (time) => {
                        if (this.pickerMinDate !== "") {
                            const one = 3 * 31 * 24 * 3600 * 1000;
                            const minTime = this.pickerMinDate - one;
                            const maxTime = this.pickerMinDate + one;
                            return time.getTime() < minTime || time.getTime() > maxTime;
                        }
                    },
                },
                Jqvalue2: 0,
                Jqoptions2: []

            };
        },
        computed: {},
        created() {
            let end = formatTime(new Date(), "yyyy-MM-dd");
            let start = formatTime(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 30), "yyyy-MM-dd");
            this.date = [end, end];
            this.getOverview();
            this.getSale();
            this.getTop5();
            this.getPark();
            this.getMap();
            this.getTjScenicSpotList2()
        },
        mounted() {
        },
        watch: {},
        methods: {
            // 今日数据
            getOverview() {
                getOverview({start_date: this.date[0], end_date: this.date[1]}).then((res) => {
                    this.today_order = res.data.today_order;
                    this.today_people = res.data.today_people;
                    this.today_price = res.data.today_price;
                });
            },
            // 获取景区数据
            getTjScenicSpotList2() {
                tjParkList().then(res => {
                    this.Jqoptions2 = []
                    this.Jqoptions2 = res.data
                    const obj = {
                        id: 0,
                        name: '全部',
                    }
                    this.Jqoptions2.unshift(obj)
                })
            },
            changeJq2() {
                console.log('Jqvalue2',this.Jqvalue2)
                this.getPark()
            },
            // 地图
            getMap() {
                getMap({start_date: this.date[0], end_date: this.date[1]}).then((res) => {
                    this.mapList = res.data;
                    this.ysAll = 0
                    this.sjbAll = 0
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].id == 13 || res.data[i].id == 1 || res.data[i].id == 25 || res.data[i].id == 15 || res.data[i].id == 14 || res.data[i].id == 16) {
                            this.ysAll += parseInt(res.data[i].num)
                        }
                        if (res.data[i].id == 22) {
                            this.sjbAll = res.data[i].num
                        }
                    }
                });
            },
            // 客源地：省内颜色
            show1(index1, width) {
                let color = "";
                color = this.colorList[index1]
                return `border-left: ${width} solid ${color};`;
            },
            // 销量top5(数据总览-停车营收)
            getTop5() {
                // getTop5({start_date:this.date[0],end_date:this.date[1]}).then((res) => {
                //     this.top5List = res.data;
                // });
                tjOverviewParkIncome({start_date: this.date[0], end_date: this.date[1]}).then((res) => {
                    this.xData1 = []
                    let data = res.data.list;
                    this.allNum = res.data.total_income || 0;
                    data.forEach((item) => {
                        this.xData1.push({name: item.title, value: item.value, percent: item.percent});
                    });
                    this.loading1 = false;
                    this.getoptions1();
                });
            },
            // 客源地：省内-图表
            getoptions1() {
                this.options1 = {
                    //hover气泡
                    tooltip: {
                        trigger: "item",
                        position: "right",
                        // formatter: "{b}<br/>{a} : {c}元 {d}%",
                        formatter: "{b}<br/>{a} : {c}元<br/>占比: {d}%",
                    },
                    //修改图表颜色
                    color: this.colorList,

                    //graphic控制环形中间出现字，其中有两个对象，就是两行字
                    graphic: [
                        {
                            type: "text", //控制内容为文本文字
                            left: "center",
                            top: "125px", //调整距离盒子高处的位置
                            style: {
                                fill: "#1cffff", //控制字体颜色
                                text: this.allNum, //控制第一行字显示内容
                                fontSize: 16,
                            },
                        },
                        {
                            type: "text",
                            left: "center",
                            top: "95px",
                            z: 10,
                            style: {
                                text: "总收入金额(元)",
                                fontSize: 12,
                                fill: "#FFFFFF",
                            },
                        },
                    ],
                    series: [
                        {
                            name: "停车收入",
                            type: "pie",
                            radius: ["50%", "70%"],
                            center: ["50%", "45%"],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: "center",
                            },
                            labelLine: {
                                show: false,
                            },
                            data: this.xData1,
                        },
                    ],
                };
            },
            // 游客总量-日期
            handleDate() {
                this.getOverview();
                this.getSale();
                this.getTop5();
                // this.getPark();
                this.getMap();
            },
            // 停车数据
            getPark() {
                let params = {
                    scenic_spot_id: this.Jqvalue2[0] || 0,
                    park_id: this.Jqvalue2[1] || 0,
                };
                getPark(params).then((res) => {
                    this.park = res.data;
                });
            },
            // 销售额
            getSale() {
                getSale({start_date: this.date[0], end_date: this.date[1]}).then((res) => {
                    let data = res.data.type_list;
                    this.saleList = []
                    data.forEach((item) => {
                        this.saleList.push({
                            name: item.name,
                            value: item.price,
                            format_price: item.format_price,
                            type: item.type,
                        });
                    });
                    this.allSale = res.data.today_price;
                    this.getChart();
                });
            },
            // 销售额颜色
            showBg(type) {
                let color = "";
                switch (type) {
                    case 1:
                        color = "#fb7e48";
                        break;
                    case 2:
                        color = "#098dfe";
                        break;
                    case 3:
                        color = "#ffbb00";
                        break;
                }
                return `background: ${color};`;
            },
            // 地图位点位
            point(index2) {
                let left = "50%";
                let top = "10%";

                switch (index2) {
                    case 0:
                        left = "62%";
                        top = "63%";
                        break;
                    case 1:
                        left = "43%";
                        top = "18%";
                        break;
                    case 2:
                        left = "29%";
                        top = "32%";
                        break;
                    case 3:
                        left = "44%";
                        top = "40%";
                        break;
                    case 4:
                        left = "75.5%";
                        top = "31%";
                        break;
                    case 5:
                        left = "63%";
                        top = "39%";
                        break;
                    case 6:
                        left = "61%";
                        top = "14%";
                        break;
                    case 7:
                        left = "25%";
                        top = "56%";
                        break;
                    // case 8:
                    //   left = "30%";
                    //   top = "60%";
                    //   break;
                }
                return `top: ${top}; left: ${left}`;
            },
            // 停车数据下拉
            handleCommand(v1) {
                if (v1) {
                    this.mapList.forEach((item) => {
                        if (v1 == item.id) {
                            this.chose = item.name;
                        }
                    });
                } else {
                    this.chose = "全部景区";
                }
                this.getPark(v1);
            },
            // echart
            getChart() {
                this.options = {
                    //hover气泡
                    tooltip: {
                        trigger: "item",
                        position: "right",
                        formatter: "{b}<br/>{a} : {c}元 {d}%",
                    },
                    //修改图表颜色
                    color: ["#fb7e48", "#098dfe", "#ffbb00"],

                    // legend: {
                    //   top: "5%",
                    //   left: "center",
                    // },

                    //graphic控制环形中间出现字，其中有两个对象，就是两行字
                    graphic: [
                        {
                            type: "text", //控制内容为文本文字
                            left: "center",
                            top: "88px", //调整距离盒子高处的位置
                            style: {
                                fill: "#1cffff", //控制字体颜色
                                text: this.allSale, //控制第一行字显示内容
                                fontSize: 20,
                            },
                        },
                        {
                            type: "text",
                            left: "center",
                            top: "115px",
                            z: 10,
                            style: {
                                text: "总营业额",
                                fontSize: 14,
                                fill: "#FFFFFF",
                            },
                        },
                        {
                            type: "text",
                            left: "center",
                            top: "133px",
                            z: 10,
                            style: {
                                text: "(万元)",
                                fontSize: 14,
                                fill: "#FFFFFF",
                            },
                        },
                    ],
                    series: [
                        {
                            name: "销售额",
                            type: "pie",
                            radius: ["60%", "70%"],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: "center",
                            },
                            labelLine: {
                                show: false,
                            },
                            data: this.saleList,
                        },
                    ],
                };
            },
        },
    };
</script>
<style lang="scss" scoped>
    .num {
        color: #1cffff;
    }

    .all {
        // height: 100%;
        display: flex;
        justify-content: flex-end;
        color: #fff;

        .all_l {
            position: relative;

            .l_box {
                position: absolute;

                img {
                    width: 488px;
                    margin-bottom: 56px;
                }

                .tit_box {
                    width: 180px;
                    padding: 5px 0;
                    padding-left: 20px;
                    margin-bottom: 10px;
                    background: url("../assets/img/1-title-map-bg@2x.png") no-repeat;
                    background-size: 100% 100%;
                }

                .tit {
                    height: 40px;
                    line-height: 40px;
                    margin-bottom: 40px;
                    padding-left: 20px;
                    color: #1cffff;
                    font-size: 32px;
                }
            }

            .l_img {
                position: relative;
                padding: 28px 77px 0 140px;

                .mask-box {
                    position: absolute;
                    right: 34px;
                    top: 56px;
                    width: 341px;
                    height: 181px;
                    z-index: 99;
                    background: url("../assets/img/maskBg.png") no-repeat;
                    background-size: 100% 100%;
                    padding-left: 127px;

                    .p1 {
                        font-size: 20px;
                        font-weight: 400;
                        color: #ffffff;
                        padding-top: 20px;
                    }

                    .p2 {
                        font-size: 20px;
                        font-weight: 400;
                        color: #1CFFFF;
                        margin-top: 15px;
                    }
                }

                .mask-box2 {
                    position: absolute;
                    right: 34px;
                    bottom: 156px;
                    width: 341px;
                    height: 181px;
                    z-index: 99;
                    background: url("../assets/img/maskBg.png") no-repeat;
                    background-size: 100% 100%;
                    padding-left: 127px;

                    .p1 {
                        font-size: 20px;
                        font-weight: 400;
                        color: #ffffff;
                        padding-top: 20px;
                    }

                    .p2 {
                        font-size: 20px;
                        font-weight: 400;
                        color: #1CFFFF;
                        margin-top: 15px;
                    }
                }

                img {
                    width: 1072px;
                    height: 821px;
                }

                .scenic {
                    position: absolute;

                    .scenic_top {
                        min-width: 275px;
                        height: 48px;
                        line-height: 48px;
                        margin-left: -97px;
                        margin-bottom: 6px;
                        padding-left: 76px;
                        background: url("../assets/img/map-icon1@2x.png") no-repeat;
                        background-size: 100% 100%;
                        font-size: 20px;
                        // .num {
                        //   color: #1cffff;
                        // }
                    }

                    img {
                        width: 50px;
                        height: 124px;
                    }
                }
            }
        }

        .all_r {
            width: 555px;
            padding: 25px 33px;
            overflow: auto;
            background: url("../assets/img/1-bg2@2x.png") no-repeat;
            background-size: 100% 100%;

            .r_tit {
                width: 306px;
                height: 32px;
                margin: 5px 0 0px;
            }

            .r_tit2 {
                width: 306px;
                height: 32px;
                margin: 25px 0 0px;
            }


            .echart_box {
                display: flex;
                margin-bottom: 5px;

                .echart_l {
                    width: 224px;
                    height: 224px;
                    background: url("../assets/img/1-title-sales-icon@2x.png") no-repeat;
                    background-size: 100% 100%;
                }

                .echart_r {
                    padding-top: 45px;

                    .echart_r_box {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        background: rgba(16, 31, 62, 0.6);
                        box-shadow: 0px 0px 3px 0px rgba(146, 236, 255, 0.36) inset;
                        width: 256px;
                        height: 40px;
                        margin-bottom: 8px;
                        padding: 0 20px;
                        font-size: 16px;

                        .echart_r_1 {
                            display: flex;
                            align-items: center;

                            > div {
                                width: 6px;
                                height: 2px;
                            }

                            p {
                                margin-left: 6px;
                            }

                            .echart_r_1_m {
                                height: 6px;
                                border-radius: 50%;
                                margin: 0 1px;
                            }
                        }
                    }
                }
            }

            .r_next {
                margin-top: 10px;
                min-height: 225px;


                .info-box {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .info1-box {
                        width: 120px;
                        /*height: 100px;*/
                    }

                    .echart_r {
                        /*width: 100px;*/
                        display: flex;
                        flex-wrap: wrap;
                        padding-top: 10px;
                        margin-left: 10px;

                        .echart_r_box {
                            width: 33%;
                            margin-bottom: 25px;

                            .echart_r_box1,
                            .echart_r_box2 {
                                white-space: nowrap;
                                height: 20px;
                                line-height: 20px;
                                padding-left: 18px;
                                font-size: 15px;
                                color: #9bb0cc;
                            }

                            .echart_r_box2 {
                                margin-left: 2px;
                                font-size: 16px;
                                font-weight: 700;
                                color: #1CFFFF;
                                text-shadow: 0px 0px 4px 0px rgba(55, 236, 255, 0.6);

                                span {
                                    color: #9bb0cc;
                                }
                            }
                        }
                    }


                    .info2-box {
                        /*width: 208px;*/
                        /*height: 208px;*/
                    }

                    .info3-box {
                       width: 100%;
                    }
                }

                .empty {
                    width: 494px;
                    height: 205px;
                }
            }

            .r_bottom_tit {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 5px 0 28px;

                .el-dropdown-link {
                    width: 120px;
                    height: 32px;
                    line-height: 32px;
                    margin-left: 10px;
                    background: url("../assets/img/1-title-parking-icon1@2x.png") no-repeat;
                    background-size: 100% 100%;
                    text-align: center;
                    color: rgba($color: #fff, $alpha: 0.7);
                    font-size: 14px;
                    cursor: pointer;

                    img {
                        width: 13px;
                        height: 8px;
                        margin-left: 15px;
                    }
                }
            }

            .r_bottom {
                display: flex;
                justify-content: space-between;
                text-align: center;
                font-size: 16px;
                margin-bottom: 15px;

                > div {
                    img {
                        min-width: 121px;
                        height: 61px;
                    }

                    .r_bottom_num {
                        font-size: 20px;
                        color: #1cffff;
                        margin: -10px 0 10px;
                    }
                }

                .r_bottom_2 {
                    width: 6px;
                    height: 90px;
                }
            }
        }
    }

    .select-box {
        cursor: pointer;
        // width: 300px;
        position: absolute;
        top: 0;
        right: 20px;
        z-index: 9999;
    }
</style>
<style lang="scss">
    .select-box .el-input__inner {
        height: 28px !important;
        line-height: 28px !important;
        background: #042C60 !important;
        border: none !important;
        color: #37ECFF !important;
    }

    .select-box .el-input__icon {
        line-height: 28px !important;
    }

    .select-box .el-input__inner {
        height: 28px !important;
        line-height: 28px !important;
        background: #042C60 !important;
        border: none !important;
        color: #37ECFF !important;
    }

    .select-box .el-input__icon {
        line-height: 28px !important;
    }

    .select-box
    .el-input__inner,
    .el-range-input {
        height: 28px !important;
        line-height: 28px !important;
        background: #042c60 !important;
        border: none !important;
        color: #37ecff !important;
    }

    .select-box
    .el-input__icon {
        line-height: 28px !important;
    }

    .select-box
    .el-range__icon,
    .el-range__close-icon {
        line-height: 20px !important;
    }

    .select-box
    .el-range-separator {
        color: #37ecff !important;
    }

    .select-box
    .el-input__inner,
    .el-range-input {
        height: 28px !important;
        line-height: 28px !important;
        background: #042c60 !important;
        border: none !important;
        color: #37ecff !important;
    }



    .select-box
    .el-input__icon {
        line-height: 28px !important;
    }

    .select-box
    .el-range__icon,
    .el-range__close-icon {
        line-height: 20px !important;
    }

    .is-selected {
        color: #1989FA;
    }

    .calendar-info {
        font-size: 12px;
    }

    .el-checkbox {
        margin: 0 5px 5px 0;
    }

    .el-checkbox.is-bordered + .el-checkbox.is-bordered {
        margin-left: 0;
    }


    .all-date-box
    .el-input__inner,
    .el-range-input {
        height: 28px !important;
        line-height: 28px !important;
        background: #042c60 !important;
        border: none !important;
        color: #37ecff !important;
    }

    .all-date-box
    .el-input__icon {
        line-height: 28px !important;
    }

    .all-date-box
    .el-range__icon,
    .el-range__close-icon {
        line-height: 20px !important;
    }

    .all-date-box
    .el-range-separator {
        color: #37ecff !important;
    }

</style>
